import React, { Component } from "react";
import "../../App.css";

class Head extends Component {
  render() {
    return <h1 className="header">Vocab</h1>;
  }
}

export default Head;
