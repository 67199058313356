import React, { Component } from "react";
import axios from "axios";

import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";

class Lists extends Component {
  state = {
    loading: true,
    entries: [],
    word: "",
  };

  handleData = () => {
    axios.get(process.env.REACT_APP_API_URL + "/words").then((res) => {
      const entries = res.data;
      this.setState({ entries });
      this.setState({ loading: false });
    });
  };

  handleChange = (event) => {
    this.setState({ word: event.target.value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const entry = {
      word: this.state.word,
    };

    axios
      .post(process.env.REACT_APP_API_URL + "/words", {
        word: entry.word,
      })
      .then((res) => {
        console.log(res);
        console.log(res.data);
      })
      .then(this.setState({ word: "" }))
      .then(() => this.handleData());
  };

  componentDidMount() {
    this.handleData();
  }

  render() {
    const { loading } = this.state;

    if (loading) {
      return (
        <div className="d-flex justify-content-center mt-5 mb-5">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      );
    }

    return (
      <div>
        <Form onSubmit={this.handleSubmit} className="mb-3">
          <InputGroup>
            <Form.Control
              type="text"
              name="word"
              value={this.state.word}
              onChange={this.handleChange}
            />
            <Button type="submit">Add</Button>
          </InputGroup>
        </Form>
        <ListGroup>
          {this.state.entries.map((entry) => (
            <ListGroup.Item key={entry._id}>{entry.word}</ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    );
  }
}

export default Lists;
